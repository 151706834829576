:root {
    --mainColor: #2A7630;
    --darkGray: #616161;
}

@font-face {
    font-family: "CygnusHandCustom";
    src: url("../fonts/CygnusHandCustom.otf")
}

html, body {
    height: 100%;
}

body {
    background-color: var(--mainColor);
    width: 100%;
}

* {
    text-align: center;
    font-family: "CygnusHandCustom";
    color: white;
    font-weight: normal;
    word-wrap: break-word;
}

p, li {
    font-size: 0.8em;
}

.content {
    min-height: 100vh;
}

.centeredDiv {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.logo {
    width: 80vw;
    max-width: 450px;
    padding: 1em 0;
}

.home h1 {
    font-size: 2em;
    margin: 1em 0;
}

.home button {
    background-color: transparent;
    border: none;
    width: 150px;
    margin-bottom: 2em;
    cursor: pointer;
}

button>img {
    width: 100%;
}