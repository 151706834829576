.footer {
    background-color: var(--darkGray);
    box-shadow: 0 0 1em rgba(0, 0, 0, 0.4);
}

.footer a {
    font-size: 0.8em;
}

.footer>ul {
    list-style-type: none;
    padding: 2em;
}