.info {
    min-height: calc (100vh - 10em);
    margin-top: -1.35em;
    padding: 5em 3vw;
    /* width: calc(100% - 6vw); */
}

.info p, .info li, .info h2, .info h3 {
    text-align: left;
}

.privacy p, .privacy li {
    font-size: 0.7em;
}

table, th, td {
    border: 1px solid white;
}

th {
    text-align: left;
}

thead th {
    font-size: 0.8em;
}

tbody th {
    color: rgba(255, 255, 255, 0.7);
    font-size: 0.7em;
}

table {
    width: 100%;
}

.tableWrapper {
    width: 100%;
    overflow: auto;
}